/**
 * Class NFBBlogCarousel
 *
 * Extends class NFBNormanCarousel.
 * Overwrite getElements() to fetch blog from RSS.
 */

import { customElement, property, state } from 'lit/decorators.js'
import { html, nothing, TemplateResult } from 'lit'

import NFBNormanCarousel from '@src/js/wc/carousels/base/NFBNormanCarousel'

// @ts-expect-error Lit Sass import
import blogCarouselStyle from '@web-nfb/frontend-static/design-system/wc/carousel/NFBCarouselBlogSlot.sass?lit'
import { GTM_EVENTS, GTM_LIST_CATEGORY, GTM_LIST_TYPE } from '@src/js/constants'

interface BlogEntry {
  title: string | undefined | null
  image: string | undefined | null
  link: string | undefined | null
  avatar: string | undefined | null
  author: string | undefined | null
}

@customElement('nfb-blog-carousel')
export default class NFBBlogCarousel extends NFBNormanCarousel {
  @property({ type: String, attribute: 'lang' })
  lang: 'fr' | 'en'

  @property({ type: String, attribute: 'slug' })
  slug: string

  showSeeMoreCard = true

  @state()
  entryList: BlogEntry[] | null = null

  static get styles () {
    return [
      super.styles,
      blogCarouselStyle
    ]
  }

  async connectedCallback () {
    super.connectedCallback()
    await this.updateComplete

    const domain = this.lang === 'fr' ? 'https://blogue.onf.ca' : 'https://blog.nfb.ca'
    const url = `${domain}/${this.slug}/feed`
    const stringResult = await (await fetch(`${url}`)).text()
    const xml = new window.DOMParser().parseFromString(stringResult, 'text/xml')

    this.entryList = []
    xml.querySelectorAll('item').forEach(e => {
      this.entryList!.push({
        link: e.querySelector('link')?.textContent,
        image: e.querySelector('enclosure')?.getAttribute('url'),
        title: e.querySelector('title')?.textContent,
        author: e.querySelector('creator')?.textContent,
        avatar: e.querySelector('img')?.getAttribute('src')
      })
    })
  }

  getElements () {
    return html`${this.entryList?.slice(0, 11).map((e, i) => this.getBlogCard(e, i))}`
  }

  getBlogCard (element: BlogEntry, index: number): TemplateResult<1> {
    const entry = element as BlogEntry

    const gtmAttributes = {
      event: GTM_EVENTS.SELECT_WORK,
      nfb_list_category: GTM_LIST_CATEGORY.ARTICLES,
      nfb_list_type: GTM_LIST_TYPE.CAROUSEL,
      nfb_list_name: this.carouselTitle,
      nfb_list_id: 'nfb_blog',
      nfb_version_title: element.title,
      nfb_detail: (index + 1).toString()
    }

    return html`
    <li slot="card" class="nfb-carousel__card" data-index=${index}>
      <nfb-carousel-card
        title="${entry.title}"
        href="${entry.link}"
        thumbnail-path="${entry.image}"
        gtm-attributes="${JSON.stringify(gtmAttributes)}"
        data-ui-el="carousel-card"
        is-dark=${this.isDark ?? nothing}
        secondary-variant="${this.isClothesLine ? 'no-border' : nothing}"
      >
        <div slot="legend">
          <div class="nfb-card__author">
            <img class="nfb-card__author-avatar" src="${element.avatar}" />
            <div class="nfb-card__author-name">${element.author}</div>
          </div>
        </div>
      </nfb-carousel-card>
    </li>`
  }

  render () {
    if (!this.entryList) {
      return nothing
    }

    return super.render()
  }
}
